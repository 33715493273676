import axios from 'axios';
import qs from 'qs';
import app from '@/config/app.js';
import httpRequest from '@/utils/network/request';

// 搜索框
export const searchAll = function (params) {
  const url = '/v2/search/all';
  return httpRequest.post(url, params);
};

// - 用户登陆列表查询
export const searchLoginList = function (params) {
  const url = '/v2/search/login_list';
  return httpRequest.post(url, params);
};

// - 日程列表查询
export const searchTaskList = function (params) {
  const url = '/v2/search/task_list';
  return httpRequest.get(url, params);
};

// - 会员列表查询
export const searchVipList = function (params) {
  const url = '/v2/search/vip_list';
  return httpRequest.post(url, params);
};

// 家庭 - 列表数据
export const groupList = function (params) {
  const url = '/v2/group/list';
  return httpRequest.post(url, params);
};

// - 日程列表数据
export const groupTaskList = function (params) {
  const url = '/v2/group/task_list';
  return httpRequest.post(url, params);
};

// 用户 - 列表数据
export const userList = function (params) {
  const url = '/v2/user/list';
  return httpRequest.post(url, params);
};

// - 登录列表数据
export const userLoginList = function (params) {
  const url = '/v2/user/login_list';
  return httpRequest.post(url, params);
};

// - 日程列表数据
export const userTaskList = function (params) {
  const url = '/v2/user/task_list';
  return httpRequest.post(url, params);
};

// 查询用户反馈列表
export const feedbackList = function (params) {
  const url = '/v2/feedback/list';
  return httpRequest.post(url, params);
};

// 下载
// 用户反馈 - /v2/feedback/export
// 搜索 - 登录列表 /v2/search/login_export
//     - 日程列表 /v2/search/task_export
// 家庭 - 成员列表 /v2/group/group_user_export
//     - 日程列表 /v2/group/task_export
// 用户 - vip列表 /v2/search/vip_export
//     - 登录列表 /v2/user/login_export
//     - 日程列表 /v2/user/task_export

export const exportReq = function (params) {
  if (params.data && !params.data.access_token) {params.data.access_token = app.globalData.accessToken;}
  let requestUrl = `${app.apiUrl}${params.url}?${qs.stringify(params.data)}`;
  axios({
    method: 'post',
    url: requestUrl,
    responseType: 'blob' // 重点！一定要设置responseType为blob
  })
    .then((res) => {
      // 构造blob对象，type是文件类型，详情可以参阅blob文件类型
      let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }); // 我是下载zip压缩包
      let url = window.URL.createObjectURL(blob); // 生成下载链接
      const link = document.createElement('a'); // 创建超链接a用于文件下载
      link.href = url; // 赋值下载路径
      link.target = '_blank'; // 打开新窗口下载，不设置则为在本窗口下载
      link.download = `${params.fileName}.xls`; // 下载的文件名称（不设置就会随机生成）
      link.click(); // 点击超链接触发下载
      URL.revokeObjectURL(url); // 释放URL
    })
    .catch((error) => console.log('下载失败', error));
};

// 修改VIP时间
export const modifyVip = function (params) {
  const url = '/v2/user/modify_vip';
  return httpRequest.post(url, params);
};