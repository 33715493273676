<template>
  <div class="pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="onlineList.pageNum"
      :page-sizes="[10, 20, 30]"
      :page-size="onlineList.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="onlineList.total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: ['onlineList'],
  methods: {
    // size变化
    handleSizeChange (val) {
      this.$emit('changesize', val);
    },
    // page变化
    handleCurrentChange (val) {
      this.$emit('changepage', val);
    }
  }
};
</script>

<style>
.pagination {
  margin-top: 10px;
  text-align: center;
}
</style>